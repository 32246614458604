import { MainNavItem, SidebarNavItem } from "@/types";

interface DocsConfig {
  mainNav: MainNavItem[];
  sidebarNav: SidebarNavItem[];
}

export const docsConfig: DocsConfig = {
  mainNav: [
    {
      title: "Company",
      href: "/company",
    },
    {
      title: "FEDIRALY LINK",
      href: "https://fediraly.link",
      external: true,
    },
    {
      title: "FEDIRALY Short Link",
      href: "https://fediraly.org",
      external: true,
    },
   
  ],
  sidebarNav: [
    {
      title: "Features",
      items: [
        {
          title: "FEDIRALY Frame",
          href: "https://myframe.fediraly.link",
          items: [],
          label: "New",
        },
        {
          title: "FEDIRALY Spacely",
          href: "https://spacely.fediraly.link",
          items: [],
        },
        {
          title: "FEDIRALY BIO",
          href: "https://bio.fediraly.link",
          items: [],
          label: "New",
        },
      ],
    },
  
  ],
};
