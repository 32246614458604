export const siteConfig = {
  name: "FEDIRALY",
  url: "https://fediraly.net",
  ogImage: "https://fediraly.net/api/og",
  description:
    "At FEDIRALY.NET, we're dedicated to pioneering innovation today to shape a brighter future tomorrow. Explore our suite of cutting-edge solutions designed to propel businesses and individuals forward in an ever-evolving digital landscape.",
    links: {
      twitter: "https://twitter.com/fediraly",
      discord: "https://discord.gg/",
      github: "https://github.com/",
      instagram: "https://instagram.com/fediraly/",
      LinkedIn: "https://linkedin.com/company/fediraly/",
    },
  keywords: [
    "React",
    "Tailwind CSS",
    "Framer Motion",
    "Landing Page",
    "Components",
    "Next.js",
    "fediraly",
    "achraf rzz",
    "ceo fediraly",
    "achraf razzouqi"
  ],
};

export type SiteConfig = typeof siteConfig;
