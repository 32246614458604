"use client";
import { Icons } from "@/components/icons";
import {
    ChevronRightIcon,
    LinkedInLogoIcon
} from "@radix-ui/react-icons";

const footerNavs = [
  {
    label: "Product",
    items: [
      {
        href: "https://fediraly.link",
        name: "FEDIRALY LINK",
      },
      {
        href: "https://fediraly.org",
        name: "FEDIRALY Short Links",
      },
    ],
  },
  {
    label: "Features",
    items: [
      {
        href: "https://myframe.fediraly.link",
        name: "FEDIRALY Frame",
      },
      {
        href: "https://bio.fediraly.link",
        name: "FEDIRALY BIO",
      },
      {
        href: "https://spacely.fediraly.link",
        name: "FEDIRALY Spacely",
      },

    ],
  },
  {
    label: "Company",
    items: [
      
      {
        href: "/company",
        name: "About",
      },

      {
        href: "https://support.fediraly.net",
        name: "Support",
      },
    ],
  },

  {
    label: "Legal",
    items: [
      {
        href: "/abuse",
        name: "Report Abuse",
      },
    ],
  },
];

const footerSocials = [
  {
    href: "https://linkedin.com/company/fediraly",
    name: "Linkedin",
    icon: <LinkedInLogoIcon className="size-4" />,
  },

  {
    href: "https://instagram.com/fediraly",
    name: "Instagram",
    icon: <Icons.instagram className="size-4" />,
  },
];

export function SiteFooter() {
  return (
    <footer className="border-t">
      <div className="mx-auto w-full max-w-screen-xl px-4">
        <div className="gap-4 p-4 py-16 sm:pb-16 md:flex md:justify-between">
          <div className="mb-12 flex flex-col gap-4">
            <h1  className="flex items-center gap-2">
              {/*  <img
                className="h-7 w-7"
                src="https://magicui.design/icon.png"
                alt="MagicUI Logo"
              />*/}
              <span className="self-center whitespace-nowrap text-2xl font-semibold text-neutral-900 dark:text-white">
                FEDIRALY
              </span>
            </h1>
            <div className="max-w-sm">
              <div className="z-10 mt-4 flex w-full flex-col items-start text-left">
                <p className="">Innovating Today, Building Tomorrow.</p>
                <div className="mt-4 flex items-center  p-1.5 text-sm">
                  <svg
                    height="15"
                    width="15"
                    xmlns="http://www.w3.org/2000/svg"
                    className="mr-2"
                  >
                    <circle r="6" cx="7.5" cy="7.5" fill="#0070f3" />
                  </svg>
                  <a
                    className="underline-offset-3 font-semibold text-blue-500 transition-colors hover:underline"
                    href="https://status.fediraly.net/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    All systems normal.
                  </a>

                  <span
                    className="status-indicator_indicator__Wc_JX status-indicator_success__zzQCD"
                    data-testid="footer/status/success"
                    style={{
                      flexShrink: 0,
                      display: "inline-block",
                      width: "10px",
                      height: "10px",
                      borderRadius: "100%",
                      backgroundColor: "var(--geist-success)",
                      margin: 0,
                    }}
                  ></span>
                </div>

                {/* React components to build
                <br className="hidden md:block" /> beautiful landing pages. 
                <a
                  href="/signup"
                  className={cn(
                    buttonVariants({
                      size: "lg",
                      variant: "default",
                    }),
                    "mt-4 w-full rounded-full px-6 text-sm font-semibold tracking-tighter transition-all duration-150 ease-in-out hover:ring-2 hover:ring-neutral-800 hover:ring-offset-2 hover:ring-offset-current dark:hover:ring-neutral-50",
                  )}
                >
                  Sign Up Free
                  <ChevronRightIcon className="ml-1 size-4 transition-all duration-300 ease-out group-hover:translate-x-1" />
                </a>*/}
              </div>
            </div>
          </div>
          <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 sm:gap-6 lg:grid-cols-4">
            {footerNavs.map((nav) => (
              <div key={nav.label}>
                <h2 className="mb-6 text-sm font-semibold uppercase text-neutral-900 dark:text-white">
                  {nav.label}
                </h2>
                <ul className="grid gap-2">
                  {nav.items.map((item) => (
                    <li key={item.name}>
                      <a
                        href={item.href}
                        className="group inline-flex cursor-pointer items-center justify-start gap-1 text-[15px]/snug font-medium text-neutral-400 duration-200 hover:text-neutral-700 dark:text-neutral-400 dark:hover:text-neutral-200"
                      >
                        {item.name}
                        <ChevronRightIcon className="h-4 w-4 translate-x-0 transform opacity-0 transition-all duration-300 ease-out group-hover:translate-x-1 group-hover:opacity-100" />
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>

        <div className="flex flex-col gap-2 border-t py-4 sm:flex sm:flex-row sm:items-center sm:justify-between">
          <div className="flex space-x-5 sm:mt-0 sm:justify-center">
            {footerSocials.map((social) => (
              <a
                key={social.name}
                href={social.href}
                className="fill-neutral-500 text-neutral-500 hover:fill-neutral-900 hover:text-neutral-900 dark:hover:fill-neutral-600 dark:hover:text-neutral-600"
              >
                {social.icon}
                <span className="sr-only">{social.name}</span>
              </a>
            ))}
          </div>
          <span className="text-sm tracking-tight text-neutral-500 dark:text-neutral-400 sm:text-center">
            © {new Date().getFullYear()}{" "}
            <a href="/" className="cursor-pointer"></a>
            FEDIRALY Technologies, Inc.
          </span>
        </div>
      </div>
    </footer>
  );
}
